import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import LOGO_WHITE from '../logo-white.svg';
import LogoutButton from './LogoutButton';
import SidebarMenuItem from './SidebarMenuItem';
import COMPANY_STUB_LOGO from '../company_icon.png';

const items = [
    {
        path: '/dashboard/staff',
        icon: 'fa fa-users',
        title: 'Employees'
    },
    {
        path: '/dashboard/invoices',
        icon: 'fa fa-file-text',
        title: 'Invoices'
    },
    {
        path: '/dashboard/profile',
        icon: 'fa fa-user',
        title: 'Profile'
    },
    {
        path: '/dashboard/plan-purchase',
        icon: 'fa fa-credit-card',
        title: 'Plan Purchase'
    }
];

class SidebarComponent extends React.Component {
    replaceBrokenImageSrc(event) {
        const companyLogo = event.target;
        companyLogo.setAttribute('src', COMPANY_STUB_LOGO);
    }

    render() {
        const { showSideBar, companyName, companyLogo, invoices, userType } = this.props;

        return (
            <div id="left" className={`column sidebar ${showSideBar ? '' : 'sidebar__active'}`}>
                <div className="top-left">
                    <Link to="/dashboard/profile" style={{ textDecoration: 'none' }}>
                        <div className="sidebar__top">
                            <img
                                src={companyLogo || COMPANY_STUB_LOGO}
                                alt="Company"
                                onError={this.replaceBrokenImageSrc}
                            />
                            <p>{companyName.toUpperCase()}</p>
                        </div>
                    </Link>
                </div>
                <div className="bottom">
                    <nav>
                        <ul>
                            {items.map((item, i) => {
                                if (userType === 'SME' && invoices.length === 0) {
                                    if (item.title !== 'Plan Purchase') {
                                        return null;
                                    }
                                    return (
                                        <SidebarMenuItem
                                            key={i}
                                            to={item.path}
                                            icon={item.icon}
                                            title={item.title}
                                        />
                                    );
                                }

                                if (userType === 'SME' && invoices.length > 0) {
                                    return (
                                        <SidebarMenuItem
                                            key={i}
                                            to={item.path}
                                            icon={item.icon}
                                            title={item.title}
                                        />
                                    );
                                }

                                if (userType !== 'SME') {
                                    if(item.title === 'Plan Purchase') return null;
                                    return (
                                        <SidebarMenuItem
                                            key={i}
                                            to={item.path}
                                            icon={item.icon}
                                            title={item.title}
                                        />
                                    )
                                }
                            })}
                            <li className="dashboard__menu">
                                <i className="fa fa-question-circle" />
                                <a href="mailto:hellonigeria@getreliancehealth.com?subject=Client Dashboard Help[from our client]">
                                    Help
                                </a>
                            </li>
                            <LogoutButton title="Logout" icon="fa fa-sign-out" />
                        </ul>
                    </nav>
                </div>
                <div className="bottom bottom-left">
                    <img src={LOGO_WHITE} alt="RelianceHMO's Logo" />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { invoice, display } = state;
    const { showSideBar } = display || {};
    const { invoices } = invoice;
    return { showSideBar, invoices };
}

export default withRouter(connect(mapStateToProps)(SidebarComponent));
