import React from 'react';
import Tooltip from '@mui/material/Tooltip';

class PlanCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employeeCount: 0,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.frequency !== this.props.frequency) { // ✅ Reset count on frequency change
          this.setState({
            employeeCount: 0
          })
        }
        if (prevProps.cart !== this.props.cart && this.props.cart.length === 0) {
            this.setState({
                employeeCount: 0
            })
        } 
      }

    increaseCount = () => {
        this.setState({
            employeeCount: this.state.employeeCount + 1
        });
    };

    decreaseCount = () => {
        if (this.state.employeeCount === 0) return;
        this.setState({
            employeeCount: this.state.employeeCount - 1
        });
    };

    AddToCart = () => {
        this.props?.AddToCart({
            no_of_enrollees: this.state.employeeCount,
            price: this.props.price * this.state.employeeCount,
            name: this.props.name,
            product_id: this.props.id
        });
    };

    Price = () => new Intl.NumberFormat().format(this.props.price);

    render() {
        const { employeeCount } = this.state;
        const { 
            id, 
            name, 
            price,
            error,
            itemInCart,
            familyOption, 
            toggleFamilyOption,
        } = this.props;

        const show = () => {
            if (error.id === id) {
                return 'plan-card-error'
            }
        }

        return (
            <div className={`plan-card ${show()}`}>
                <div className="plan-card-header">
                    <h2 className="plan-card-name">{name?.split('-')[0]}</h2>
                    <div className="family-option-container">
                        <input
                            onClick={() => toggleFamilyOption(id, name?.split('-')[0])}
                            type="checkbox"
                            checked={familyOption.includes(id)}
                        />
                        <label>Include Family</label>
                        <Tooltip
                            id='mui-tip'
                            arrow
                            title={
                                'Let employees add up to 5 family members, including spouse and children, to their health plans.'
                            }
                        >
                            <i className="fa fa-question-circle-o fa-1x" aria-hidden="true"></i>
                        </Tooltip>
                    </div>
                </div>
                <p className="plan-card-description">
                    Best for people that need access to premium hospitals
                    <br />
                    and extra benefits.
                </p>
                <h2 className="plan-card-price">
                    {employeeCount > 0
                        ? `N${new Intl.NumberFormat().format(price * employeeCount)}/year`
                        : `N${this.Price()}/year`}
                </h2>
                <p className="plan-card-subtext">Employee Count</p>
                <div className="plan-card-footer">
                    <div className="employee-count-box">
                        <button
                            type="button"
                            className="employee-count-button"
                            onClick={this.decreaseCount}
                        >
                            -
                        </button>
                        <p>{employeeCount}</p>
                        <button
                            type="button"
                            className="employee-count-button"
                            onClick={this.increaseCount}
                        >
                            +
                        </button>
                    </div>
                    <button
                        className={`primary_button add-to-cart-btn ${
                            itemInCart ? 'btn-active' : ''
                        }`}
                        onClick={() => !itemInCart && this.AddToCart()}
                        disabled={employeeCount === 0}
                    >
                        {itemInCart ? `Added to Cart` : 'Add Plan'}
                        {itemInCart ? <i className="fa-light fa-check added-to-cart-icon"></i> : ''}
                    </button>
                </div>
            </div>
        );
    }
}

export default PlanCard;
