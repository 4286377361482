import React from 'react';
import {Link, withRouter} from 'react-router-dom';

import PageHeader from './boxes/PageHeader';
import MainContent from './boxes/MainContent';
import * as invoiceActions from '../actions/invoice';


class CongratulationsPage extends React.Component{
    componentDidMount(){
        const {dispatch, user} = this.props;
        dispatch(invoiceActions.getClientInvoices(user.credentials.accessToken, user.companyInfo.id));
    }

    render(){
        const {history} = this.props;

        return (
        <MainContent>
            <PageHeader title="Receipt" />
                <div className="congrats-body-wrapper">
                    <h1>Congratulations you have successfully purchased your plans!</h1>
                    <p>Here is what you need to do next</p>
                    <div className="steps-card">
                    <div className="steps-card-text">
                        <div className='step-card-image-1'>
                        </div>
                        <div className="step-card-steps">
                            <h2>Step 1</h2>
                            <p>Add your employees as beneficiaries of this plan</p>
                        </div>
                    </div>
                    <button
                        className={`primary_button step-card-btn`}
                        onClick={() => this.props.history.push('/dashboard/staff')}
                        // disabled={employeeCount === 0}
                    >
                        Add Employees
                    </button>
                    </div>
                    <div className='image-div'></div>
                    <div className='dots'></div>
                    <div className="steps-card">
                    <div className="steps-card-text">
                        <div className='step-card-image-2'>
                        </div>
                        <div className='step-card-steps'>
                            <h2>Step 2</h2>
                            <p>Check your email for further instructions on how things work with small business health plans</p>
                        </div>
                    </div>
                    </div>
                    <div className='image-div'></div>
                    <div className='dots'></div>
                    <div className="steps-card">
                    <div className="steps-card-text">
                        <div className='step-card-image-3'>
                        </div>
                        <div className='step-card-steps'>
                            <h2>Step 3</h2>
                            <p>Once registration is complete, we’ll send out HMO IDs. Your employees will then be fully activated and ready to start using our services. Welcome aboard!</p>
                        </div>
                    </div>
                    </div>
                    <div className='image-div'></div>
                </div>
            </MainContent>
        )
    }
}


export default withRouter(CongratulationsPage);