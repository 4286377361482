import axios from 'axios';
import querystring from 'querystring';
import * as _ from 'lodash';
import {
    DOMAIN_COOKIE_NAME,
    getCookie,
    DAARA_COOKIE_NAME,
    DAARA_TOKEN_PREFIX
} from './utilityService';
import features from './daara-config.json';
export const getAPIbaseUrl = () => {
    switch (process.env.REACT_APP_ENV) {
        case "local":
        case "v3test":
        case "development":
            return "https://api.staging.reliancehmo.com/v3";
        case "staging":
            return "https://api.staging.reliancehmo.com/v3";
        default:
            return "https://api.reliancehmo.com/v3";
    }
}

const BASE_URL_RELIANCE = getAPIbaseUrl();

export const getRedirectDomain = (redirectDomain) => {
    let domainParts = window.location.host.split('.');
    if (domainParts.length === 1) {
        return `${redirectDomain}.reliancehmo.com`;
    } else {
        domainParts[0] = redirectDomain;
        return domainParts.join('.');
    }
};

const getDomain = () => {
    switch (process.env.REACT_APP_ENV) {
        case 'local':
        case 'v3test':
        case 'development':
            return 'develop.reliancehmo.com';
        case 'daara':
            return 'daara.develop.reliancehmo.com';
        case 'daara-staging':
            return 'daara.staging.reliancehmo.com';
        case 'staging':
            return 'staging.reliancehmo.com';
        default:
            return 'reliancehmo.com';
    }
};

export const DOMAIN = getDomain();

class ClientService {
    constructor() {
        this.ax = axios.create({
            baseURL: BASE_URL_RELIANCE,
            headers: {
                DAARA_TOKEN: getCookie(DAARA_COOKIE_NAME)
            }
        });
        this.data = {};
    }

    auth() {
        let accessToken = getCookie(DOMAIN_COOKIE_NAME);
        let daaraToken = getCookie(DAARA_COOKIE_NAME);
        return this.ax
            .post(`/auth/clients`, null, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                let client = mapClient(response.data.data.accounts);
                client.credentials.accessToken = accessToken;
                client.credentials.daaraToken = daaraToken;
                if (features.account_service.auth) {
                    localStorage.setItem(
                        `${DAARA_COOKIE_NAME}_${DAARA_TOKEN_PREFIX}`,
                        client.credentials.daaraToken
                    );
                }
                return client;
            });
    }

    getOverViewData(accessToken, clientId) {
        this.data = querystring.stringify({
            access_token: accessToken
        });

        return this.ax
            .get(`/clients/${clientId}/overview`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    getUsageData(accessToken, numberOfDays, clientId) {
        this.data = {
            num_of_days: numberOfDays
        };
        return this.ax
            .get(
                `/clients/${clientId}/usage`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                },
                this.data
            )
            .then((response) => {
                return response.data.data;
            });
    }

    getInvoices(accessToken, clientId) {
        return this.ax
            .get(`/clients/${clientId}/invoices`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

	changeLogo(logo){
		const formData = new FormData();
		formData.append("file_use","client_logo");
		formData.append("file", logo);
		return this.ax.post(`/upload`, formData,{
				header:{
						"Content-Type":"multipart/form-data"
				}
		})
		.then((response)=>{
				return response.data.data;
		});
	}

    updateProfile(email, phone, logo_filename, accessToken, client_id) {
        this.data = querystring.stringify({
            email_address: email,
            phone_number: phone,
            logo_filename
        });
        return this.ax
            .patch(`/clients/${client_id}/profile`, this.data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.message;
            });
    }

    getStaff(accessToken, clientId, queryData) {
        if (queryData.invoice_ref_code === '') delete queryData.invoice_ref_code;
        return this.ax
            .get(`/clients/${clientId}/enrollees?${querystring.stringify(queryData)}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data;
            });
    }

    getInvoiceByReferenceCode(refCode, accessToken, clientId) {
        return this.ax
            .get(`/clients/${clientId}/invoices/${refCode}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    getInvoicePlanMasterList(refCode, accessToken, clientId) {
        return this.ax
            .get(`/clients/${clientId}/invoices/${refCode}/list-download`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    getAllPlans(client_id) {
        return this.ax.get(`/products?client_id=1&source=smewebsignup`).then((response) => {
            return response.data.data;
        });
    }

    purchasePlan(orderDetails, access_token) {
        return this.ax.post(`/cart`, orderDetails, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`
            }
        }).then((response) => {
            return response.data.data;
        });
    }

    getClientCards(client_id, access_token) {
        return this.ax
            .get(`/clients/${client_id}/cards`, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    removeClientCard(client_id, access_token, cardId) {
        return this.ax
            .delete(`/clients/${client_id}/cards/${cardId}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    newInvoice(invoice, access_token) {
        invoice.callback_url = '/payment-successful';
        return this.ax
            .post(`/cart`, invoice, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${access_token}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    addSingleEmployee(
        firstName,
        lastName,
        email,
        phone,
        productId,
        numOfDependants,
        accessToken,
        clientId,
        invoiceRefCode
    ) {
        this.data = {
            first_name: firstName,
            last_name: lastName,
            email_address: email,
            phone_number: phone,
            product_id: productId,
            number_of_dependants: numOfDependants || 0
        };

        return this.ax
            .post(`/clients/${clientId}/invoices/${invoiceRefCode}/enrollees`, this.data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    addMultipleEmployees(formData, accessToken, clientId, refCode) {
        return this.ax
            .post(`/clients/${clientId}/invoices/${refCode}/list-upload`, formData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }
	addMultipleEmployees(formData, accessToken, clientId, refCode){
		return this.ax.post(`/clients/${clientId}/invoices/${refCode}/list-upload`,formData, {
			headers: {
				"Authorization": `Bearer ${accessToken}`
			}
		}).then((response)=>{
			return response;
		})
	}
    getClientAdminAccountById(accessToken, clientId) {
        return this.ax
            .get(`/clients/${clientId}/profile`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    getStaffById(id, accessToken, clientId) {
        return this.ax
            .get(`/clients/${clientId}/enrollees/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    editEmployee(userId, firstName, lastName, email, phone, accessToken, clientId) {
        this.data = querystring.stringify({
            first_name: firstName,
            last_name: lastName,
            email_address: email,
            phone_number: phone
        });

        return this.ax
            .patch(`/clients/${clientId}/enrollees/${userId}`, this.data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.message;
            });
    }

    removeEnrollee(userId, accessToken, clientId) {
        return this.ax
            .delete(`/clients/${clientId}/enrollees/${userId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.message;
            });
    }

    changePaymentFrequency(accessToken, clientId, invoiceRefCode, frequency) {
        return this.ax
            .put(
                `/clients/${clientId}/invoices/${invoiceRefCode}?${querystring.stringify({
                    frequency
                })}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            )
            .then((response) => {
                return response.data.message;
            });
    }
}

export function mapClient(accounts) {
    return {
        companyInfo: {
            id: _.get(accounts[0], 'id'),
            name: _.get(accounts[0], 'name'),
            email: _.get(accounts[0], 'email_address'),
            phoneNumber: _.get(accounts[0], 'phone_number'),
            address: _.get(accounts[0], 'address'),
            logoUrl: _.get(accounts[0], 'logo'),
            industry: _.get(accounts[0], 'industry'),
            canModify: _.get(accounts[0], 'can_modify'),
            type: _.get(accounts[0], 'type')
        },
        adminInfo: {
            position: _.get(accounts[0], 'position'),
            accounts: accounts
        },
        credentials: {
            accessToken: _.get(accounts[0], 'access_token'),
            daaraToken: _.get(accounts[0], 'daara_token'),
            refreshToken: _.get(accounts[0], 'daara_refresh_token')
        },
        preferences: {
            allowNotifications: '',
            selectedClient: accounts.length > 1 ? -1 : _.get(accounts[0], 'id') //if client admin has multiple accounts, set selectedClient ot -1 so that they select which client they want
        }
    };
}

export default new ClientService();
