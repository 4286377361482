import React from 'react';
import clientService from '../../services/client';
import {Field, change, reduxForm} from 'redux-form';
import LoadingScreen from '../LoadingScreen';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

class AddMultipleEmployeeForm extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            isChecking:false,
            canAddEmployees: false,
            masterListUrl: "",
            errorMessage:""
        }
    }

    componentDidMount(){

        const {dispatch, accessToken, refCode, clientId} = this.props;

        dispatch(change("addMultipleEmployeeForm", "access_token",accessToken));
        dispatch(change("addMultipleEmployeeForm", "ref_code",refCode));
        dispatch(change("addMultipleEmployeeForm", "client_id",clientId));

        this.setState({
            isChecking: true
        });

        clientService.getInvoicePlanMasterList(refCode, accessToken, clientId)
            .then((data)=>{
                this.setState({
                    isChecking: false,
                    canAddEmployees: true,
                    masterListUrl: data.url
                })
            }, (err)=>{
                this.setState({
                    isChecking: false,
                    canAddEmployees: false,
                    masterListUrl: "",
                    errorMessage: err?.response?.data?.message
                })
            })

    }

    render(){
        const {handleSubmit, pristine, submitting } = this.props;

        const { isChecking, canAddEmployees, masterListUrl, errorMessage } = this.state;

        if(isChecking) return <LoadingScreen/>;

        if(!isChecking && !canAddEmployees)
            return (
                <div style={{display:"flex", justifyContent:"center", flexDirection:"column", height:"400px"}}>
                    <div style={{ padding: "2rem",background: "red", color: "white",fontSize: "1.3em"}}>
                        {errorMessage}
                    </div>
                </div>
            );

        if(!isChecking && canAddEmployees)
        return (
            <form onSubmit={handleSubmit}>
                <h3>Adding Multiple Employees:</h3>
                <p>To add multiple employees at once, please click the button below to download a formatted Excel Spreadsheet, where you will fill in the details of the Employees you wish to add.</p>
                <p>&nbsp;</p>
                <div className="multiple-upload-section">
                <a
                    href={masterListUrl}
                    className="form-button content"
                    style={{display:"block", textDecoration:"none", textAlign:"center"}}>
                    Download CSV Template
                </a>
                <p>&nbsp;</p>
                <p>When you are done filling up the SpreadSheet, click the button below to select the file, then upload the file</p>
                    <div className="file-upload-wrapper">
                    <label className="file-upload-icon-button" for="file">
                        <FileUploadOutlinedIcon />
                        <Field component={CustomFileInput} name="file" type="hidden" style={{display:"none"}}/>
                    </label>

                    <p>Choose your file for upload</p>
                    <p>&nbsp;</p>
                    <span>CSV, XLS</span>
                    </div>
                <Field component="input" name="access_token" type="hidden" style={{display:"none"}}/>
                <Field component="input" name="client_id" type="hidden" style={{display:"none"}}/>
                <Field component="input" name="ref_code" type="hidden" style={{display:"none"}}/>
                <p>&nbsp;</p>
                <button
                    className="form-button upload"
                    // style={{backgroundColor:"#2eaf31"}}
                    type="submit"
                    disabled={pristine || submitting}>
                    Upload File
                </button>
                </div>
            </form>
        );
    }
}

AddMultipleEmployeeForm = reduxForm({
    form:"addMultipleEmployeeForm"
})(AddMultipleEmployeeForm);

const CustomFileInput = (field, hidden) => {
    delete field.input.value; // <-- just delete the value property
    return <input type="file" id="file" hidden {...field.input}/>;
};

export default AddMultipleEmployeeForm;